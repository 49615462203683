.container {
  min-height: 100vh;
  padding: 10vh;
  background-size: cover;
  background-position: center center;
  background-image: url("https://images.unsplash.com/photo-1604275291560-55f54cec0e4d?q=80&w=4896&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  position: relative;

  &::after {
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(12px);
    z-index: 1;
  }
}

.wrap {
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.33);
  color: #fff;
}

.list {
  margin: 0;
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin-top: 3rem;
}

.item {
  width: 100%;
  aspect-ratio: 16/9;
  padding: 3rem;
  background: #fff;
  border-radius: 1.5rem;
  cursor: pointer;

  &.createNew {
    align-items: center;
    background: var(--secondary-color);
    display: flex;
    justify-content: center;
    text-align: center;
    color: #fff;
    cursor: unset;
  }
}
