.container {
  align-content: center;
  background: var(--gray-dark);
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: var(--navbar-height);
  z-index: 9999;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.saveFeedback {
  svg {
    width: 1.5rem;
    height: auto;

    * {
      stroke: var(--primary-color);
    }
  }
}
