/* Variables */
:root {
  --landing-dark-color: #57a281;
  --landing-header-height: 100px;
  --landing-header-height-tablet-and-mobile: 70px;
  --landing-header-sticky-height: 70px;
  --landing-header-sticky-height-tablet-and-mobile: 70px;
}

body {
  --bs-body-font-size: 0.8rem;
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.landing {
  min-height: 100vh;
}

/* Utility classes */
.landing-dark-bg {
  background: linear-gradient(to right, #4b937f 0%, #6abd87 100%);
}

.landing-intro {
  font-size: 50px;
  font-style: italic;
}

.landing-yellow-color {
  color: #fff486;
}

.landing-dark-color {
  color: var(--landing-dark-color);
}

.landing-dark-border {
  border: 1px dashed #2c3f5b;
}

.landing-dark-separator {
  border-top: 1px dashed #2c3f5b;
}

.landing-curve {
  position: relative;
}

.landing-curve svg {
  position: relative;
  top: 0;
  display: block;
}

/* Landing header */
.landing-header {
  display: flex;
  align-items: center;
  height: var(--landing-header-height);
}

.landing-header .logo-default {
  display: block;
}

.landing-header .logo-sticky {
  display: none;
}

.landing-header .menu .menu-link.active {
  color: white;
}

/* Sticky header modes */
[data-kt-sticky-landing-header="on"] .landing-header {
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  background-color: white;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  height: var(--landing-header-sticky-height);
}

[data-kt-sticky-landing-header="on"] .landing-header .logo-sticky {
  display: block;
}

[data-kt-sticky-landing-header="on"] .landing-header .logo-default {
  display: none;
}

[data-kt-sticky-landing-header="on"] .landing-header .menu .menu-link.active {
  background-color: rgba(239, 242, 245, 0.4);
}

/* Fix body padding top when Landing sticky header is on */
body[data-kt-sticky-landing-header="on"] {
  padding-top: var(--landing-header-height);
}

/* Tablet & mobile modes */
@media (max-width: 991px) {
  /* Landing header */
  .landing-header {
    height: var(--landing-header-height-tablet-and-mobile);
  }

  .landing-header .landing-menu-wrapper {
    position: relative;
    overflow: auto;
  }

  [data-kt-sticky-landing-header="on"] .landing-header {
    height: var(--landing-header-sticky-height-tablet-and-mobile);
  }

  .landing-header .menu .menu-link.active {
    background-color: rgba(239, 242, 245, 0.4);
  }

  /* Fix body padding top when Landing sticky header is on */
  body[data-kt-sticky-landing-header="on"] {
    padding-top: var(--landing-header-height-tablet-and-mobile);
  }
}

.top-container {
}

.hero-container {
  color: #fff486;
  display: flex;
  justify-content: center;
  width: 1500px;
}

.landing-hero {
  width: 800px;
  text: left;
}

.partner-logos {
  width: 1500px;
}

.partner-logo {
  height: 70px;
}

/* Class to set background image to no-repeat */
.bgi-no-repeat {
  background-repeat: no-repeat;
}

/* Class to set the background image position to center along the x-axis */
.bgi-position-x-center {
  background-position-x: center;
}

.call-to-action {
  font-weight: bold; /* Makes the font bold */
  font-style: italic; /* Makes the font italic */
  font-size: 70px; /* Sets the font size to 70 pixels */
}

.hero-text {
  font-size: 25px;
}
