.languageSwitcher {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 1.33rem;
    aspect-ratio: 4/3;
    border-radius: 0.2em;
  }
}
