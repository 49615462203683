:root {
  --primary-color: #a5eb50;
  --secondary-color: #2f5852;

  --gray-darker: #232323;
  --gray-dark: #282828;
  --gray-medium: #333333;
  --gray-light: #585858;

  --red: #ff5050;
  --green: var(--primary-color);

  --error-color: rgba(255, 0, 0, 0.15);
  --success-color: rgba(165, 235, 80, 0.4);

  --border-color: rgba(47, 88, 42, 0.33);

  --small-grid-gutter: 0.75rem;
  --grid-gutter: 1.5rem;

  --text-bold: 600;

  --bg-light: #fcf6c1;

  --navbar-height: 64px;

  /* Easings */
  --ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  --ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  --ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  --ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  --ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
  --ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
  --ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
  --ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
  --ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
  --ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
  --ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
  --ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
  --ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);

  --ease-bounce: cubic-bezier(0.5, 1.64, 0.41, 0.8);
}
body {
  --bs-body-font-size: 0.7rem;
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f2f2;
  overscroll-behavior: none;
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0.625em;
  font-weight: var(--text-bold);
}

.text-green {
  color: var(--green);
}

.text-red {
  color: var(--red);
}

::selection {
  background: var(--primary-color);
  color: #000;
}

.subtitle {
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0.33rem;
  font-size: 0.65rem;
  color: rgba(255, 255, 255, 0.65);
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--small-grid-gutter);
}

.form-grid--large-gutter {
  grid-gap: var(--grid-gutter);
}

.project {
  display: grid;
  grid-template-columns: 768px auto;

  @media screen and (min-width: 1920px) {
    grid-template-columns: 960px auto;
  }
}

.project__map {
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;

  /* &::after {
    pointer-events: none;
    background: rgba(165, 235, 80, 0.25);
    mix-blend-mode: overlay;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999999999;
  } */
}

/* Style for the right-side toolbar to take full height */
.project__toolbar {
  background-color: var(--gray-dark);
  color: #fff;
  height: 100vh; /* Full viewport height */
  position: relative;
  z-index: 2;
}

.project-loading {
  align-items: center;
  background: var(--secondary-color);
  display: flex;
  height: calc(100% - var(--navbar-height));
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: var(--navbar-height);
  transition: 0.66s var(--ease-out-expo);
  width: 100%;
  z-index: 99999;
}

.toolbar-inner {
  overflow-y: scroll;
  height: 100vh;
}

.toolbar-inner::-webkit-scrollbar {
  width: 8px;
  background: rgba(255, 255, 255, 0.1);
}

.toolbar-inner::-webkit-scrollbar-track {
  border-radius: 10px;
}

.toolbar-inner::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
}
.toolbar-inner::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 1);
}

.toolbar-content {
  flex-direction: column;
  display: flex;
  position: relative;
  padding-top: var(--navbar-height);
}

.toolbar-loading {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 999;

  animation-duration: 1.75s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loadingGradient;
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    var(--gray-dark) 0%,
    #303030 40%,
    var(--gray-dark) 70%
  );
}

@keyframes loadingGradient {
  0% {
    background-position: -30vw 0;
  }
  100% {
    background-position: 70vw 0;
  }
}

.toolbar-loading svg {
  width: 3rem;
  height: 3rem;
}

.toolbar-box {
  padding: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  transition: 2s var(--ease-out-expo);
}

.is-loading .toolbar-box {
  opacity: 0;
}

.toolbar-table {
  /* user-select: none; */
  border-collapse: initial;
  border-spacing: 2px;
  cursor: default;
  margin: -2px;
  width: calc(100% + 4px);
  line-height: 1.2;
}
.toolbar-table thead th {
  /* text-align: left;
  padding: 8px;
  border: 1px solid #0a3730;
  border-collapse: separate;
  border-spacing: 0
  white-space: nowrap; */
  padding: 0 12px;
}

.toolbar-table thead th svg {
  width: 1.625em;
  height: 1.625em;
}

.toolbar-table tbody tr {
  /* color: #222214;
  text-align: left; */
}

.toolbar-table tbody tr:hover td {
  filter: contrast(80%);
}

.toolbar-table tbody tr.is-totals {
  position: relative;
  line-height: 1.8;

  &::after {
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.15);
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.toolbar-table tbody td {
  white-space: nowrap;
  background-color: var(--gray-medium);
  padding: 10px 12px;
  transition: 0.3s ease;
}
.toolbar-table tbody td.td-fixed-width {
  width: 15%;
  overflow: hidden;
}

.toolbar-table tbody tr.is-totals td {
  background-color: transparent;
  border-radius: 0;
  padding-bottom: 4px;
  padding-top: 4px;
}

.toolbar-table tbody td.highlighted {
  background: var(--gray-light);
}

.toolbar-table tbody td:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.toolbar-table tbody td:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.toolbar-table .selectedReduction {
  color: var(--primary-color);
  font-weight: bold;
}
