.container {
  animation: dropdown 0.75s var(--ease-out-expo) forwards;
  background: #fff;
  border-radius: 12px;
  color: #000;
  padding: 1rem;
  position: absolute;
  right: 0;
  top: calc(100% + 4px);
  z-index: 10000;
  position: absolute;
  box-shadow: 0 1em 2em rgba(0, 0, 0, 0.2);
  min-width: 192px;

  &.top {
    bottom: calc(100% + 4px);
    top: unset;
  }

  &.alignLeft {
    right: unset;
    left: 0;
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    cursor: pointer;
    font-weight: var(--text-bold);

    a {
      text-decoration: none;
      color: #000;
    }

    & + li {
      margin-top: 1rem;
    }
  }
}

@keyframes dropdown {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0rem);
  }
}
