.container {
  align-items: center;
  display: inline-flex;
  gap: 0.5em;
  z-index: 60000;
}

.tooltip {
  position: absolute;
  //   transform: translateX(-50%);
  background: rgba(0, 0, 0, 1);
  color: #fff;
  padding: 0.5rem;
  border-radius: 4px;
  z-index: 99999999999999;
  white-space: nowrap;
  pointer-events: none;
  text-transform: none;
  font-size: 12px;

  &.top {
    bottom: calc(100% + 0.15rem);
  }

  &.bottom {
    top: calc(100% + 0.15rem);
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}

.icon {
  svg {
    height: 1em;
    min-height: 16px;
    width: auto;
    color: #fff;
  }
}
