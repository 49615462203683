.container {
  background: var(--primary-color);
  border-radius: 10px;
  border: none;
  font-weight: var(--text-bold);
  max-width: 100%;
  min-width: 200px;
  padding: 0.5rem 2rem;
  transition: 0.25s ease;
  font-size: 14px;

  &:hover,
  &:focus {
    background: var(--secondary-color);
    color: #fff;
  }

  &.large {
    padding: 0.75rem 1rem;
  }

  &.darkGreen {
    background: var(--secondary-color);
    color: #fff;
  }

  &.outline {
    background: transparent;
    border: 1px solid #000;
    color: #000;

    &.invertColor {
      border-color: #fff;
      color: #fff;

      &:hover {
        border-color: var(--primary-color);
      }
    }
  }

  &.ghost {
    background: transparent;
    color: #000;

    &.invertColor {
      border-color: #fff;
      color: #fff;

      &:hover {
        border-color: var(--primary-color);
      }
    }
  }

  &.autoWidth {
    padding: 0.5rem;
    min-width: 0;
  }

  &.hasIcon {
    padding: 0.5rem 0.5rem 0.5rem 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.icon {
  align-items: center;
  background: #fff;
  display: flex;
  color: #000;
  height: 1.33rem;
  justify-content: center;
  width: 1.33rem;
  border-radius: 4px;
  font-size: 0.625em;
  transition: inherit;

  svg {
    height: auto;
    width: 70%;
  }

  .container:hover & {
    background: var(--primary-color);
  }
}
