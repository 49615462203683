.container {
  position: relative;
  width: 100%;
}

.input {
  background: transparent;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  color: #000;
  padding: 0.5rem 0.5rem;
  position: relative;
  width: 100%;
  transition: 0.4s var(--ease-out-expo);
  background-color: white;

  // &:invalid {
  //   border: 1px solid red;
  // }

  .transparent & {
    background: transparent !important;
  }

  &.large {
    padding: 0.75rem 1rem;
  }

  .invertColor & {
    border: 1px solid #fff;
    color: #fff;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.33);

    .invertColor & {
      color: rgba(255, 255, 255, 0.33);
    }
  }

  &[type="text"] {
  }

  &[type="number"] {
    &::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      opacity: 1;
    }
  }

  &[type="checkbox"] {
    -webkit-appearance: none;
    border-radius: 4px;
    border: 1px solid #fff;
    cursor: pointer;
    height: 1rem;
    width: 1rem;

    &:checked {
      background: var(--primary-color) !important;
      border-color: transparent;

      &::after {
        align-items: center;
        color: #000;
        content: "✓";
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0.05em;
        width: 100%;
        pointer-events: none;
      }
    }
  }

  &:focus,
  &:hover {
    border-color: var(--secondary-color);
    outline: currentColor;
    box-shadow: 0 0.33rem 1rem rgba(0, 0, 0, 0.08);

    .invertColor & {
      border-color: var(--primary-color);
      box-shadow: 0 0.33rem 1rem rgba(255, 255, 255, 0.08);
    }
  }
}

.label {
  font-weight: 600;
  margin-bottom: 0.25rem;
}
.innerLabel {
  align-items: center;
  display: flex;
  font-size: 10px;
  height: 100%;
  pointer-events: none;
  position: absolute;
  right: 0.5rem;
  top: 0;
  z-index: 2;
  color: #000;

  .isNumber & {
    opacity: 0.5;
    right: 2rem;
  }

  .invertColor & {
    color: #fff;
  }
}
