.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 0;
  padding: 0;
  background: transparent;
  color: inherit;
  font-weight: bold;

  &:hover .button {
    background: var(--primary-color);
    color: #000;
  }
}

.button {
  align-items: center;
  background: #333;
  border: 0;
  color: #fff;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  width: 1.5rem;
  transition: 0.3s ease;
  border-radius: 0.4rem;
}
