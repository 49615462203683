.main {
  position: relative;
}
.navWrapper {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;
}

.navbar {
  color: black;
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.navbarContent {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.navItems {
  display: flex;
}

.right {
  display: flex;
  align-items: center;
}

.languageSwitcher {
  margin-right: var(--grid-gutter);
}

.container {
  position: relative;
}
