.container {
  background: #fff;
  border-radius: 2rem;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: calc(100% + 1.5rem);
  max-width: 400px;
  min-height: 30vh;
  padding: 1.75rem;
  position: absolute;
  top: calc(var(--navbar-height) + 1.5rem);
  width: 100%;
}

.numbers {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    align-items: center;
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    padding: 0.5rem 0;

    span {
      &:nth-child(2) {
        font-weight: var(--text-bold);
      }
    }

    & + li {
      border-top: 1px solid var(--border-color);
    }
  }
}
