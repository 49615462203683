.overlay {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99998;
  animation: overlay 0.66s var(--ease-out-expo) forwards;
}

@keyframes overlay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;

  --modal-padding: 1.5rem;
}

.modal {
  max-width: 90%;
  width: 100%;
  background: white;
  border-radius: 1rem;
  color: #000;
  pointer-events: auto;
  animation: modal 0.66s var(--ease-out-expo) forwards;
  overflow: hidden;
  border: var(--modal-border);

  @media (min-width: 768px) {
    max-width: 400px;
  }
}

.header {
  align-items: flex-start;
  border-bottom: var(--modal-border);
  display: flex;
  justify-content: space-between;
  padding-left: var(--modal-padding);
  padding-right: var(--modal-padding);
  padding-top: var(--modal-padding);
}

.title {
  margin: 0;
}

.close {
  background: #fff;
  border-radius: 99px;
  border: 0;
  padding: 0;
  padding: 0.33rem;
  transition: 0.66s var(--ease-out-expo);
  transform: rotate(-90deg);

  &:hover {
    transform: rotate(0deg);
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.content {
  padding: var(--modal-padding);
}

.buttons {
  align-items: center;
  display: flex;
  display: grid;
  grid-gap: 0.75rem;
  grid-template-columns: 1fr;
  justify-content: space-between;
  padding-bottom: var(--modal-padding);
  padding-left: var(--modal-padding);
  padding-right: var(--modal-padding);
}

@keyframes modal {
  0% {
    opacity: 0;
    transform: translateY(4rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
