.infoText {
  max-width: 200px;
}

.flex {
  display: flex;
}

.infoWrapper td {
  background: #585858 !important;
}
