.container {
  height: 100vh;
  width: 100%;
  display: flex;
  background-color: var(--bg-light);
  align-items: center;
  // justify-content: center;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.wrap {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.form {
  max-width: 440px;
  width: 100%;
}
.languageSwitcher {
  position: fixed;
  top: var(--small-grid-gutter);
  left: var(--small-grid-gutter);
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 0.5em;

  button {
    border: 1px solid var(--border-color);
    background: #fff;
    border-radius: 0.33em;
    aspect-ratio: 1/1;
  }
}

.navigation {
  position: relative;
  margin: 0 0 4rem;

  .activeMarker {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 1px;
    background: var(--secondary-color);
    transition: 0.5s var(--ease-in-out-expo);

    &.isActive {
      transform: translateX(100%);
    }
  }

  ul {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    list-style: none;
    padding: 0;

    li {
      width: 50%;
      padding: 0.75rem 0;

      button {
        background: transparent;
        border: 0;
        font-weight: var(--text-bold);
        padding: 0;
        width: 100%;
        text-align: left;
      }
    }
  }
}

.formGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--grid-gutter);
}

.title {
  // text-align: center;
}

.register {
  // text-align: center;

  button {
    color: inherit;
    font-weight: var(--text-bold);
    border: 0;
    background: transparent;
    text-decoration: underline;
  }
}

.image {
  display: none;
  height: 100vh;
  position: relative;
  width: 100%;

  @media (min-width: 1024px) {
    display: block;
  }

  img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
