.container {
  width: 100%;

  // height: 100vh;
  // width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.animate {
    animation: fadeInAnimation 1.25s var(--ease-out-expo) forwards;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: translateX(4rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.wrap {
  max-width: 440px;
  width: 100%;
}
