.container {
  align-items: center;
  border-radius: 8px;
  display: flex;
  font-weight: var(--text-bold);
  gap: 0.5em;
  padding: 0.75rem 1rem;

  &.success {
    background: var(--success-color);

    svg {
      fill: var(--text-color);
    }
  }
  &.error {
    background: var(--error-color);

    svg {
      fill: red;
    }
  }

  &.small {
    padding: 0.375rem 0.5rem;
    font-size: 0.65em;
  }
}
