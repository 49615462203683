.container {
  width: 100%;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    align-items: center;
  }
}

.status {
  font-size: 10px;
  padding: 4px 6px;
  line-height: 1;
  border-radius: 99px;
  font-weight: var(--text-bold);
  transition: 0.5s var(--ease-out-expo);
  //   text-shadow: 0 1px 0px rgba(0, 0, 0, 0.2);
}
.barWrap {
  position: relative;
  margin-top: 0.5em;
}
.slider {
  background: #000;
  border-radius: 99px;
  height: 20px;
  left: 0;
  position: absolute;
  top: -7px;
  width: 6px;
  transform: translateX(-50%);
  transition: 1s var(--ease-out-expo);
}
.bar {
  display: flex;
  align-items: center;
  font-size: 10px;
  justify-content: space-between;
  gap: 3px;
}

.item {
  text-align: center;
  flex: 1;
  display: flex;
  gap: 0.5em;
  flex-direction: column;

  &:first-child {
    text-align: left;

    .itemBar {
      border-top-left-radius: 99px;
      border-bottom-left-radius: 99px;
    }
  }

  &:last-child {
    text-align: right;

    .itemBar {
      border-top-right-radius: 99px;
      border-bottom-right-radius: 99px;
    }
  }

  .itemBar {
    width: 100%;
    height: 6px;
  }
}

.disclaimer {
  margin-top: 0.5em;
  text-decoration: underline;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
}
